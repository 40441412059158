











































import { Component, Vue } from 'vue-property-decorator'
import LabelBase from '@/components/atoms/LabelBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import { ServiceCodeEnum, ServiceCodeType } from '@/types/teacher/service'

type ServiceOneSubject = {
  id: number
  name: string
  isEnabled: boolean
  isRequesting: boolean
  comment: string
  onChange: Function
}
@Component({
  components: {
    LabelBase,
    TableBase,
    RadioToggle,
  },
})
export default class RequestEdit extends Vue {
  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId
  private CALENDAR_SERVICE_ID = 4

  private kyoshituDatas: {
    id: number
    code: string
    name: string
    services: {
      id: number
      name: string
      ko: {
        id: number
        subjectId: number
        checked: boolean
        on: string
        off: string
        disabled: boolean
        comment: string
        onChange: Function
      }
      su: {
        id: number
        subjectId: number
        checked: boolean
        on: string
        off: string
        disabled: boolean
        comment: string
        onChange: Function
      }
      ei: {
        id: number
        subjectId: number
        checked: boolean
        on: string
        off: string
        disabled: boolean
        comment: string
        onChange: Function
      }
      ri: {
        id: number
        subjectId: number
        checked: boolean
        on: string
        off: string
        disabled: boolean
        comment: string
        onChange: Function
      }
      sh: {
        id: number
        subjectId: number
        checked: boolean
        on: string
        off: string
        disabled: boolean
        comment: string
        onChange: Function
      }
    }[]
    calendar: ServiceOneSubject
    gpt: ServiceOneSubject
  }[] = []

  private kyosituTableBasePerPage?: number

  private kyosituTableBaseFields = [
    { key: 'name', label: 'サービス名' },
    { key: 'su', label: '算数/数学' },
    { key: 'ei', label: '英語' },
    { key: 'ko', label: '国語' },
    { key: 'ri', label: '理科' },
    { key: 'sh', label: '社会' },
  ]

  private kyosituTableBaseToggles = ['su', 'ei', 'ko', 'ri', 'sh']

  /**
   * 一括申請状況
   * 一括申請中の場合すべての個別申請が不可となる
   */
  private isBulkRequested = false

  /**
   * 学研のservises.idを保持
   * GDLSの削除申請をしたときに学研の削除申請を自動的に行うのに利用
   */
  private serviceIdGakken: number | null = null

  private mounted() {
    this.loadDatas()
  }

  private async loadDatas() {
    // 一括申請状況を取得(未処理のもののみ取得 status: 3)
    const paramsRequests = [`academyId=${this.academyId}`, 'status=3', 'requestType=3']
    await Vue.prototype.$http.httpWithToken.get(`/requests?${paramsRequests.join('&')}`).then((res: any) => {
      if (res.data.requests.length > 0) this.isBulkRequested = true
    })

    // 申請一覧を取得
    const paramsBranchServices = [`academyId=${this.academyId}`]
    await Vue.prototype.$http.httpWithToken
      .get(`/v3/branches/services?${paramsBranchServices.join('&')}`)
      .then((res: any) => {
        let gdlsService: any
        this.kyoshituDatas = res.data.branches.map(
          // [1階層目] branchにデータを投入
          (branch: { id: number; code: string; name: string; services: any }, branchIndex: number) => {
            let serviceOfAllSubject = {}
            let services = branch.services.map(
              // [2階層目] servicesにデータを投入
              (service: { id: number; name: string; subjects: any; code: ServiceCodeType }, serviceIndex: number) => {
                const serviceCode = service.code
                if ([ServiceCodeEnum.CALENDAR_SERVICE_CODE, ServiceCodeEnum.GPT_SERVICE_CODE].includes(serviceCode)) {
                  const serviceCalendar = service.subjects[0]
                  serviceOfAllSubject[serviceCode] = serviceCalendar
                  serviceOfAllSubject[serviceCode].comment = this.responseBranchServiceComment(serviceCalendar)
                  serviceOfAllSubject[serviceCode].onChange = this.responseBranchServiceOnChange(
                    serviceCalendar,
                    branch.id,
                    service.id,
                    branchIndex,
                    serviceIndex,
                    1,
                    service.code
                  )
                  if (serviceCalendar.isRequesting) {
                    serviceOfAllSubject[serviceCode].isEnabled = !serviceCalendar.isEnabled
                  }
                }
              }
            )

            const servicesNotCalendar = branch.services.filter(
              (service: any) => ![ServiceCodeEnum.CALENDAR_SERVICE_CODE, ServiceCodeEnum.GPT_SERVICE_CODE].includes(service.code)
            )
            services = servicesNotCalendar.map(
              // [2階層目] servicesにデータを投入
              (service: { id: number; name: string; subjects: any; code: string }, serviceIndex: number) => {
                let subjects: any = []
                subjects = [...Array(5)].map((_: any, subjectIndex: number) => {
                  // [3階層目] subjectsにデータを投入
                  if (service.name == 'GDLS') gdlsService = service
                  if (service.name == '学研動画') {
                    if (!this.serviceIdGakken) this.serviceIdGakken = service.id
                  }

                  return {
                    id: service.subjects[subjectIndex].branchServiceId, // 申請サービスid
                    subjectId: service.subjects[subjectIndex].subjectId, // 教科id
                    checked: this.responseBranchServiceChecked(service.subjects[subjectIndex]), // 有効かどうか
                    on: '',
                    off: '',
                    disabled: this.responseBranchServiceDisabled(
                      service.subjects[subjectIndex],
                      service.name,
                      gdlsService.subjects[subjectIndex]
                    ), // ボタン操作可能か
                    immediatDisabled: true, // ボタン押下で即時押せなくする指定を子コンポーネント用に設定
                    radioToggleConfirm: this.radioToggleConfirm(service.name, service.subjects[subjectIndex].isEnabled),
                    comment: this.responseBranchServiceComment(service.subjects[subjectIndex]), // 申請中の利用状況により文言表示
                    onChange: this.responseBranchServiceOnChange(
                      service.subjects[subjectIndex],
                      branch.id,
                      service.id,
                      branchIndex,
                      serviceIndex,
                      subjectIndex
                    ),
                  }
                })

                // [2階層目]
                return {
                  id: service.id,
                  name: service.name,
                  su: subjects[0],
                  ei: subjects[1],
                  ko: subjects[2],
                  ri: subjects[3],
                  sh: subjects[4],
                }
              }
            )

            const calendarFinal = serviceOfAllSubject[ServiceCodeEnum.CALENDAR_SERVICE_CODE]
            const gptFinal = serviceOfAllSubject[ServiceCodeEnum.GPT_SERVICE_CODE]
            serviceOfAllSubject = {}
            // [1階層目]
            return {
              id: branch.id,
              code: branch.code,
              name: branch.name,
              services: services,
              calendar: calendarFinal,
              gpt: gptFinal,
            }
          }
        )
      })
  }

  /**
   * 申請ボタン押下時に呼び出すメソッド
   * 引数はonChangeのところでボタンごとにあらかじめ設定する
   *
   * @param params あらかじめ設定しておいた引数。詳細はresponseBranchServiceOnChangeを参照
   * @return 申請APIをたたいた後、結果によってボタンの色を変えたり文言変更したりする
   */
  private async requestApi(params: {
    serviceId: number
    branchId: number
    subjectId: number
    value: boolean
    branchIndex: number
    serviceIndex: number
    subjectKey: number
    serviceCode?: ServiceCodeType
  }): Promise<void> {
    const serviceId = params.serviceId
    const branchId = params.branchId
    const subjectId = params.subjectId
    const value = params.value
    const branchIndex = params.branchIndex
    const serviceIndex = params.serviceIndex
    const subjectKey = params.subjectKey
    const serviceCode = params.serviceCode
    const data =
      serviceCode && [ServiceCodeEnum.CALENDAR_SERVICE_CODE, ServiceCodeEnum.GPT_SERVICE_CODE].includes(serviceCode)
        ? {
            branchId: branchId,
            serviceId: serviceId,
          }
        : {
            branchId: branchId,
            serviceId: serviceId,
            subjectId: subjectId,
          }
    await Vue.prototype.$http.httpWithToken
      .post('/v3/requests', {
        requestType: value ? 1 : 2,
        academyId: this.academyId,
        requests: [data],
      })
      .then(() => {
        // 操作不可に変更
        if (serviceCode && ServiceCodeEnum.CALENDAR_SERVICE_CODE === serviceCode) {
          this.kyoshituDatas[branchIndex].calendar.isRequesting = true
          this.kyoshituDatas[branchIndex].calendar.comment = value ? '追加申請中' : '削除申請中'
        } else if (serviceCode && ServiceCodeEnum.GPT_SERVICE_CODE === serviceCode) {
          this.kyoshituDatas[branchIndex].gpt.isRequesting = true
          this.kyoshituDatas[branchIndex].gpt.comment = value ? '追加申請中' : '削除申請中'
        } else {
          this.kyoshituDatas[branchIndex].services[serviceIndex][subjectKey].disabled = true
          // 文言表示
          this.kyoshituDatas[branchIndex].services[serviceIndex][subjectKey].comment = value
            ? '追加申請中'
            : '削除申請中'
          // GDLSを削除申請した場合に学研動画も削除申請する
          this.requestApiGakkenByGdlsDelete(branchId, subjectId, value, branchIndex, serviceIndex, subjectKey)
        }
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('すでに申請中のため申請できないか、エラーが発生しました。')
          location.reload()
        }
      })
  }

  private checkChangeRadio(data: any, checked: boolean): Function | null {
    if (data.onChange && data.onChange.func) {
      return data.onChange.func(data.onChange.funcParams, checked)
    }

    return null
  }

  /**
   * Web APIレスポンスパラメータから申請サービスが有効か取得
   * 申請中の場合は利用状況を反転する
   *
   * @param data service.subjects[subjectIndex] ある教室のあるサービスのある教科の申請状況
   * @return リクエスト中の場合は現在の有効状況の反対を返し、そうでない場合は現在の有効状況を返す
   */
  private responseBranchServiceChecked(data: any): boolean {
    return data.isRequesting ? !data.isEnabled : data.isEnabled
  }

  /**
   * Web APIレスポンスパラメータから申請サービスが有効か取得
   * 個別申請、一括申請中は申請操作不可とする
   *
   * @param data service.subjects[subjectIndex] ある教室のあるサービスのある教科の申請状況
   * @param service_name サービス名
   * @param dataGdls GDLSのservice.subjects[subjectIndex] ある教室のあるサービスのある教科の申請状況
   * @return 申請操作できるか
   *         GDLSが有効でない場合は無条件で操作不可とする
   */
  private responseBranchServiceDisabled(data: any, service_name: string, dataGdls: any): boolean {
    let result

    // 個別申請、一括申請中は申請操作不可とする
    result = this.isBulkRequested || data.isRequesting

    if (service_name == '学研動画') {
      // GDLSが有効でない場合は無条件で操作不可とする
      result = !dataGdls.isEnabled ? true : result
      // GDLSが削除申請中の場合は無条件で操作不可とする
      result = dataGdls.isEnabled && dataGdls.isRequesting ? true : result
    }

    return result
  }

  /**
   * Web APIレスポンスパラメータからボタンに表示する文言を設定
   * 申請中でかつ申請サービス状況が有効かそうでないかで文言が変わる
   *
   * @param data service.subjects[subjectIndex] ある教室のあるサービスのある教科の申請状況
   * @return ボタン文言
   */
  private responseBranchServiceComment(data: any): string {
    let result = ''

    // 有効中で削除申請している場合は「削除申請中」、追加申請している場合は「追加申請中」
    if (data.isRequesting) result = data.isEnabled ? '削除申請中' : '追加申請中'

    return result
  }

  /**
   * ボタン押下時に呼び出すメソッドとその引数を設定する
   *
   * @param data
   * @param branchId
   * @param serviceId
   * @param branchIndex
   * @param serviceIndex
   * @param subjectIndex
   * @return メソッドとその引数を返す。引数のデータをもとにボタンを反転したり文言表示したりする
   *         branchId: 教室id
   *         serviceId: サービスid
   *         value: 申請予定の利用可不可（現在の状況の反対）
   *         branchIndex: 申請データ（this.kyoshituDatas）の1階層目の教室情報があるデータのインデックス
   *         serviceIndex: 申請データ（this.kyoshituDatas）の2階層目のサービス情報があるデータのインデックス
   *         subjectKey: 申請データ（this.kyoshituDatas）の3階層目の教科情報があるデータのインデックス
   */
  private responseBranchServiceOnChange(
    data: any,
    branchId: number,
    serviceId: number,
    branchIndex: number,
    serviceIndex: number,
    subjectIndex: any,
    serviceCode?: ServiceCodeType
  ): { [key: string]: any } {
    return {
      func: this.requestApi,
      funcParams: {
        branchId: branchId,
        serviceId: serviceId,
        subjectId: data.subjectId,
        value: !data.isEnabled,
        branchIndex: branchIndex,
        serviceIndex: serviceIndex,
        subjectKey: this.kyosituTableBaseToggles[subjectIndex],
        serviceCode,
      },
    }
  }

  /**
   * GDLSを削除申請した場合に学研動画も削除申請する
   */
  private requestApiGakkenByGdlsDelete(
    branchId: number,
    subjectId: number,
    value: boolean,
    branchIndex: number,
    serviceIndex: number,
    subjectKey: number
  ) {
    // 利用申請が利用可の場合は何もしない
    if (value) return
    // GDLSの利用申請以外の場合は何もしない
    if (this.kyoshituDatas[branchIndex].services[serviceIndex].name !== 'GDLS') return

    for (let i = 0; i < this.kyoshituDatas[branchIndex].services.length; i++) {
      // 学研動画ではない場合は何もしない
      if (this.kyoshituDatas[branchIndex].services[i].name !== '学研動画') continue

      // 学研動画の利用状況が有効だった場合、削除申請を行う
      if (this.kyoshituDatas[branchIndex].services[i][subjectKey].checked) {
        // ラジオボタンをトグル
        this.kyoshituDatas[branchIndex].services[i][subjectKey].checked = false
        // 自動的に申請APIに投げる
        this.requestApi({
          branchId: branchId,
          serviceId: this.serviceIdGakken!,
          subjectId: subjectId,
          value: false,
          branchIndex: branchIndex,
          serviceIndex: i,
          subjectKey: subjectKey,
        })

        // 削除申請を行ったら即終了する
        break
      } else {
        // 学研動画の利用状況が無効だった場合、削除申請や追加申請は行わないがボタン操作を無効にする
        this.kyoshituDatas[branchIndex].services[i][subjectKey].disabled = true
      }
    }
  }

  private radioToggleConfirm(serviceName: string, isEnabled: boolean): { [key: string]: any } {
    return {
      isAlert: serviceName === 'GDLS' && isEnabled === true,
      message: 'GDLSを削除申請すると学研動画も削除申請対象となります。',
    }
  }
}
